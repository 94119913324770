.App{
  height: 100%;
  width: 100%;
  position: absolute;
}
.ge-title {
  display: flex;
  margin-left: 10px
}

.ge-title .ge-title-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px
}

.ge-title .ge-title-icon div {
  background-color: #c20000;
  height: 18px;
  -webkit-transform: skew(-15deg);
  transform: skew(-15deg);
  width: 14px
}

.ge-title .ge-title-content {
  color: #fff
}

@media (min-width: 0em) and (max-width:800px) {
  .game-video-card {
      width:100%!important
  }
}
