.loading-wrap {
    align-items: center;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    background-color: #000;
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999999999
}

.loading-wrap .loading-content {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 50px;
    transition: .5s;
    width: 50px
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}
@-webkit-keyframes gp-fadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes gp-fadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}