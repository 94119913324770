* {
  box-sizing: border-box;
  letter-spacing: .05em
}

blockquote,body,button,dd,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,hr,html,input,legend,li,ol,p,pre,td,textarea,th,ul {
  margin: 0;
  padding: 0
}

body,button,input,select,textarea {
  font: 12px/"Noto Sans SC";
  font-weight: 300
}

h1,h2,h3,h4,h5,h6 {
  font-size: 100%
}

address,cite,dfn,em,var {
  font-style: normal
}

code,kbd,pre,samp {
  font-family: couriernew,courier,monospace
}

small {
  font-size: 12px
}

ol,ul {
  list-style: none
}

a:hover {
  text-decoration: underline
}

sup {
  vertical-align: text-top
}

sub {
  vertical-align: text-bottom
}

legend {
  color: #000
}

fieldset,img {
  border: 0
}

@font-face {
  font-family: SourceHanSansCN-Medium;
  src: url('./assets/font/SourceHanSansCN-Medium.otf') format("truetype")
}

.not_found {
  background: url('./assets/img/404.jpg') no-repeat top;
  height: 100vh;
  width: 100vw
}

::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none
}

img {
  border-style: none
}

a {
  color: hsla(0,0%,100%,.514)
}

a,a:hover,a:link,a:visited {
  text-decoration: none
}

a:hover {
  color: red
}

a:active {
  text-decoration: none
}

*,:after,:before {
  background-repeat: no-repeat!important;
  box-sizing: border-box!important;
  color: #fff
}

:after,:before {
  text-decoration: inherit;
  vertical-align: inherit
}
.mobile {
  display: block
}

@media (min-width: 801px) {
  .mobile {
      display:none!important
  }
}

@media (min-width: 0em) and (max-width:800px) {
  .desktop {
      display:none!important
  }
}
