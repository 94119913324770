.ge-goTop {
    align-items: center;
    background-color: #c20000;
    bottom: 140px;
    cursor: pointer;
    height: 50px;
    justify-content: center;
    position: fixed;
    right: 20px;
    width: 50px;
    z-index: 99999999
}

.ge-goTop,.goTopShow {
    display: flex
}

.goTopHide {
    display: none
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}